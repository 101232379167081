/* You can add global styles to this file, and also import other style files */

@import '@grandview/style/antd-components-theme.scss';
@import '@grandview/style/tooltip.scss';
@import '@grandview/style/animate.css';
// AntD reset
@import 'antd/dist/reset.css';
// Site reset
@import '@grandview/style/reset.scss';

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--ab-dark-primary-9) !important;
}
